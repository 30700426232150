/**
 * Meta component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Meta({ description, lang, meta, keywords, title, slug, shouldIndex = true, children }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						title
						description
						author
					}
				}
			}
		`
	)

	const metaDescription = description || site.siteMetadata.description
	const metaTitle = title || site.siteMetadata.title

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			titleTemplate={`%s | Maytronics`}
			meta={[
				{
					name: `robots`,
					content: `${shouldIndex ? 'index, follow' : 'noindex, nofollow'}`,
				},
				{
					name: `googlebot`,
					content: `${shouldIndex ? 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' : 'noindex, nofollow'}`,
				},
				{
					name: `bingbot`,
					content: `${shouldIndex ? 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' : 'noindex, nofollow'}`,
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:url`,
					content: site.siteMetadata.siteUrl + slug,
				},
				{
					property: `og:locale`,
					content: lang,
				},
				{
					property: `og:type`,
					content: meta.type,
				},
				{
					property: `og:title`,
					content: metaTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:site_name`,
					content: `Maytronics Australia`,
				},
				// meta.type === `artical` && {
				// 	property: `article:publisher`,
				// 	content: `https://facebook.com/MaytronicsAustralia`,
				// },
				// meta.type === `artical` && {
				// 	property: `article:modified_time`,
				// 	content: meta.createdAt,
				// },
				{
					property: `og:image`,
					content: meta.image.url,
				},
				{
					property: `og:image:width`,
					content: meta.image.width,
				},
				{
					property: `og:image:height`,
					content: meta.image.height,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: metaTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			]}
		>
			<title>{metaTitle}</title>
			{children}
			{slug && lang === 'en-NZ' && (
				<link
					rel="canonical"
					href={`https://www.maytronics.com.au/en-NZ/${slug && slug + '/'}`}
				/>
			)}
			{slug && lang === 'en-ZA' && (
				<link
					rel="canonical"
					href={`https://www.maytronics.com.au/en-ZA/${slug && slug + '/'}`}
				/>
			)}
			{slug && lang === 'en-AU' &&(
				<link
					rel="canonical"
					href={`https://www.maytronics.com.au/${slug && slug + '/'}`}
				/>
			)}
			<script type="application/ld+json" className="schema-graph">
				{JSON.stringify(
					{
						'@context': 'https://schema.org',
						'@graph': [
							{
								'@type': 'Corporation',
								'@id': 'https://www.maytronics.com.au/#corporation',
								name: 'Maytronics Australia',
								url: 'https://www.maytronics.com.au/',
								alternateName: 'Dolphin electric pool cleaner',
								foundingDate: '1983',
								foundingLocation: "Kibbutz Yizre'el",
								parentOrganization: "Kibbutz Yizre'el Holdings 2004 ACS Ltd",
								brand: 'Dolphin',
								location: 'Brisbane, Queensland',
								address: '2/91 Rudd Street (entry off Boundary Road), Oxley QLD 4075',

								sameAs: [
									"https://global.maytronics.com/",
									"https://www.youtube.com/c/MaytronicsAU",
									"https://www.instagram.com/maytronics_australia/",
									"https://www.facebook.com/MaytronicsAustralia/",
									"https://github.com/maytronics",
									"https://twitter.com/maytronicsau",
									"https://www.maytronics.com/en-us/",
									"https://www.maytronics.com/en-ca/",
									"https://www.maytronics.co.il/",
									"https://www.maytronics.com.es/",
									"https://www.maytronics.com.de/",
									"https://www.maytronics.it/",
									"https://www.robot-dolphin.fr/",
									"https://www.maytronics.com.au/",
								],
								logo: {
									'@type': 'ImageObject',
									'@id': 'https://www.maytronics.com.au/#logo',
									inLanguage: lang,
									url: 'https://www.maytronics.com.au/maytronics.svg',
									width: 150,
									height: 36,
									caption: 'Maytronics Australia',
								},
								image: { '@id': 'https://www.maytronics.com.au/#logo' },
								contactPoint: {
									"@type": "ContactPoint",
									telephone: "1300693659",
									email: "infoau@maytronics.com",
									contactType: "customer service",
									areaServed: [
									  "AU",
									  "NZ",
									  "PH",
									  "SG"
									],
									availableLanguage: "en"
							  	}
							},
							{
								'@type': 'WebSite',
								'@id': 'https://www.maytronics.com.au/#website',
								url: 'https://www.maytronics.com.au/',
								name: site.siteMetadata.title,
								description: site.siteMetadata.description,
								publisher: {
									'@id': 'https://www.maytronics.com.au/#organization',
								},
								potentialAction: [
									{
										'@type': 'SearchAction',
										target:
											'https://www.maytronics.com.au/?s={search_term_string}',
										'query-input': 'required name=search_term_string',
									},
								],
								inLanguage: lang,
							},
							{
								'@type': 'WebPage',
								'@id': `https://www.maytronics.com.au/${slug &&
									slug + '/#webpage'}`,
								url: `https://www.maytronics.com.au/${slug && slug + '/'}`,
								name: metaTitle,
								isPartOf: { '@id': 'https://www.maytronics.com.au/#website' },
								primaryImageOfPage: {
									'@id': `https://www.maytronics.com.au/${slug &&
										slug + '/#primaryimage'}`,
								},
								inLanguage: lang,
								potentialAction: [
									{
										'@type': 'ReadAction',
										target: [
											`https://www.maytronics.com.au/${slug && slug + '/'}`,
										],
									},
								],
							},
						],
					},
					null,
					0
				)}
			</script>
			{/* <!-- Meta Pixel Code --> */}
<script>
{`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '246870742502371');
fbq('track', 'PageView');
`}
</script>
<noscript>{`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=246870742502371&ev=PageView&noscript=1"
/>`}</noscript>
{/* <!-- End Meta Pixel Code --> */}
		</Helmet>
	)
}

Meta.defaultProps = {
	lang: `en-AU`,
	meta: {
		type: `website`,
		createdAt: JSON.stringify(new Date(), null, 0),
		updatedAt: JSON.stringify(new Date(), null, 0),
		image: { url: ``, width: ``, height: `` },
	},
	keywords: [],
	description: ``,
	slug: ``,
	type: `website`,
}

Meta.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.object,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	slug: PropTypes.string,
	children: PropTypes.node,
	shouldIndex: PropTypes.bool
}

export default Meta
